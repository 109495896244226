<template>
  <v-icon class="ms-4 mt-1" small :color="color">mdi-circle</v-icon>
</template>

<script>
export default {
     name: 'Indicator',
     props: {color: null}
}
</script>

